body, p, span, h1, h2, h3, h4, h5, h6, a {
  font-family: 'Segoe UI', Tahoma, Verdana, sans-serif !important;
}

button {
  font-family: 'Titilium Web', sans-serif !important;
}

button.title-button, .btn-title {
  font-family: 'Titilium Web', sans-serif !important;
}

:root {
  --border-color: #08143d10;
  --border-gradient: linear-gradient(#08143d, #08143d10);
  --button-text-color: #08143d;
}

[data-theme='dark'],
.landing-page-wrapper,
.select-game-wrapper,
.create-organization {
  --border-color: #ffffff10;
  --border-gradient: linear-gradient(#ffffff, #ffffff10);
  --button-text-color: #ffffff;
}
